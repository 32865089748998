.center {
    display: flex;
    justify-content: center;
}

.row {
    display: flex;
}

.gap {
    gap: 85px;
}

.column {
    display: flex;
    flex-direction: column;
}

.nav__photo {
    width: 200px;
}

.cart {
    position: absolute;
    right: 25px;
    top: 30px;
    font-weight: 500;
    font-size: 12px;
}

@media (min-width: 600px) {
    .nav__photo {
        width: 350px;
    }

    .cart {
        top: 45px;
    }
}

