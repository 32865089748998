.footer {
    background-color: rgb(237, 249, 159);
    height: 250px;
}

.center {
    display: flex;
    padding-top: 10px;
    justify-content: center;
}

.info {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: rgb(225, 64, 64);
    text-decoration: none;
}